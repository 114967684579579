<template>
    <div class="layout-sidebar" @click="onSidebarClick">
        <AppMenu :model="menu_ame" @menuitem-click="onMenuItemClick" />
    </div>
</template>
<script>

import AppMenu from "../AppMenu.vue";

export default {
    emits: ["change-theme"],
    data() {
        return {
            menu_ame: [

                {
                    "label": "Gestão de Faturas",
                    "icon": "pi pi-fw pi-home",
                    "to": "/dashboard",
                    "items": [
                        {
                            "label": "Home",
                            "icon": "pi pi-fw pi-home",
                            "to": "/dashboard"
                        }
                    ]
                },
                {
                    "label": "GESTÃO EMPPRESAS",
                    "items": [
                        {
                            "label": "Empresas",
                            "icon": "pi pi-fw pi-file-pdf",
                            "to": "/empresas"
                        },
                        {
                            "label": "Usuários Empresas",
                            "icon": "pi pi-fw pi-user-edit",
                            "to": "/usuarios"
                        }
                    ]
                },
                {
                    "label": "Gestão Unidades",
                    "icon": "pi pi-fw pi-sitemap",
                    "items": [

                        {
                            "label": "Unidades",
                            "icon": "pi pi-fw pi-file-pdf",
                            "items": [
                                {
                                    "label": "Dados Básicos",
                                    "icon": "pi pi-fw pi-file-pdf",
                                    "to": "/unidades/dados-basicos"
                                },
                                {
                                    "label": "Tipos de Tensão",
                                    "icon": "pi pi-fw pi-file-pdf",
                                    "to": "/unidades/tipos-tensao"
                                },
                                {
                                    "label": "Tipos de Insumo",
                                    "icon": "pi pi-fw pi-file-pdf",
                                    "to": "/unidades/tipos-de-insumo"
                                },
                                {
                                    "label": "Tipos Classe Tarifária",
                                    "icon": "pi pi-fw pi-file-pdf",
                                    "to": "/unidades/tipos-classes-tarifarias"
                                },
                                {
                                    "label": "Tipos SubGrupo Tarifário",
                                    "icon": "pi pi-fw pi-file-pdf",
                                    "to": "/unidades/tipos-subgrupo-tarifario"
                                },
                                {
                                    "label": "Tipos Sub Classe",
                                    "icon": "pi pi-fw pi-file-pdf",
                                    "to": "/unidades/tipos-subclasse"
                                },
                                {
                                    "label": "Tipos Sub Mercado",
                                    "icon": "pi pi-fw pi-file-pdf",
                                    "to": "/unidades/tipos-submercado"
                                },

                                {
                                    "label": "Tipos de Modalidades Tarifárias",
                                    "icon": "pi pi-fw pi-file-pdf",
                                    "to": "/unidades/tipos-modalidade-tarifaria"
                                },
                            ]
                        },
                        {
                            "label": "Usinas",
                            "icon": "pi pi-fw pi-file-pdf",
                            "items": [
                                {
                                    "label": "Dados Básicos",
                                    "icon": "pi pi-fw pi-file-pdf",
                                    "to": "/usinas"
                                },
                                {
                                    "label": "Rateios",
                                    "icon": "pi pi-fw pi-file-pdf",
                                    "to": "/rateios"
                                }
                            ]
                        },
                        {
                            "label": "Concessionarias",
                            "icon": "pi pi-fw pi-file-pdf",
                            "items": [
                                {
                                    "label": "Dados Básicos",
                                    "icon": "pi pi-fw pi-file-pdf",
                                    "to": "/concessionarias"
                                },
                                {
                                    "label": "Tipos",
                                    "icon": "pi pi-fw pi-file-pdf",
                                    "to": "/concessionarias/tipos"
                                }
                            ]


                        },

                    ]
                },
                {
                    "label": "Faturas",
                    "icon": "pi pi-fw pi-sitemap",
                    "items": [
                        {
                            "label": "Implantar Faturas",
                            "icon": "pi pi-fw pi-file-pdf",
                            "to": "/faturas/implantar"
                        },
                        {
                            "label": "Fila de Implantação",
                            "icon": "pi pi-fw pi-file-pdf",
                            "to": "/faturas/fila"
                        },
                        {
                            "label": "Implantações",
                            "icon": "pi pi-fw pi-file-pdf",
                            "to": "/faturas/implantacao"
                        },
                        {
                            "label": "Cockpit Modos de Falha",
                            "icon": "pi pi-fw pi-file-pdf",
                            "to": "/faturas/cockpit"
                        },
                    ]
                },
                {
                    "label": "Dicionário de Dados",
                    "icon": "pi pi-fw pi-sitemap",
                    "items": [
                        {
                            "label": "Itens Chaves",
                            "icon": "pi pi-fw pi-file-pdf",
                            "to": "/faturas/itens-chaves"
                        },
                        {
                            "label": "Vincular Itens",
                            "icon": "pi pi-fw pi-file-pdf",
                            "to": "/faturas/vincular"
                        },


                    ]
                },
                {
                    "label": "RPA",
                    "icon": "pi pi-fw pi-sitemap",
                    "items": [
                        {
                            "label": "Fila",
                            "icon": "pi pi-fw pi-file-pdf",
                            "to": "/rpa/fila"
                        },
                        {
                            "label": "Cockpit",
                            "icon": "pi pi-fw pi-file-pdf",
                            "to": "/rpa/cockpit"
                        },


                    ]
                },
                {
                    "label": "Configurações",
                    "items": [
                        {
                            "label": "Usuários Adm.",
                            "icon": "pi pi-fw pi-user-edit",
                            "to": "/usuarios"
                        }
                    ]
                },
                {
                    "items": [
                        {
                            "label": "Sair",
                            "icon": "pi pi-fw pi-exclamation-circle",
                            command: () => {
                                this.axios
                                    .post("/usuarios/sair")
                                    .then(() => {
                                        localStorage.removeItem("token");
                                        localStorage.removeItem("user");
                                        this.$router.push("/");
                                    })
                                    .catch(() => {

                                    });

                            },

                        }
                    ]
                }
            ],
            // menu_ame: [

            //     {
            //         "label": "Gestão de Faturas",
            //         "icon": "pi pi-fw pi-home",
            //         "to": "/dashboard",
            //         "items": [
            //             {
            //                 "label": "Home",
            //                 "icon": "pi pi-fw pi-home",
            //                 "to": "/dashboard"
            //             }
            //         ]
            //     },

            //     {
            //         "label": "RPA",
            //         "icon": "pi pi-fw pi-sitemap",
            //         "items": [
            //             {
            //                 "label": "Fila",
            //                 "icon": "pi pi-fw pi-file-pdf",
            //                 "to": "/faturas/fila"
            //             },
            //             {
            //                 "label": "Cockpit",
            //                 "icon": "pi pi-fw pi-file-pdf",
            //                 "to": "/faturas/cockpit"
            //             },


            //         ]
            //     },

            //     {
            //         "items": [
            //             {
            //                 "label": "Sair",
            //                 "icon": "pi pi-fw pi-exclamation-circle",
            //                 command: () => {
            //                     this.axios
            //                         .post("/usuarios/sair")
            //                         .then(() => {
            //                             localStorage.removeItem("token");
            //                             localStorage.removeItem("user");
            //                             this.$router.push("/");
            //                         })
            //                         .catch(() => {

            //                         });

            //                 },

            //             }
            //         ]
            //     }
            // ],
        }
    },
    components: {
        AppMenu: AppMenu
    },
}
</script>